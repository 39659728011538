import Header2 from "@atoms/Header2";
import Layout from "@organisms/Layout";
import { graphql, withPrefix } from "gatsby";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect, useState } from "react";
import { DeviceType } from "types";

const AboutUsPage = () => {
  const { t } = useTranslation("about-us");

  const [deviceType, setDeviceType] = useState<DeviceType>("mobile");

  const getDeviceType = (width: number): DeviceType => {
    if (width <= 450) {
      return "mobile";
    } else if (width <= 768) {
      return "tablet";
    } else if (width <= 1020) {
      return "laptop";
    } else if (width > 1024) {
      return "desktop";
    }
  };

  const componentDidMount = () => {
    setDeviceType(getDeviceType(window.innerWidth));

    const onResize = () => {
      setDeviceType(getDeviceType(window.innerWidth));
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  };

  useEffect(componentDidMount, []);

  return (
    <Layout>
      <Helmet>
        <title>BARAKA | {t("Über uns")}</title>
      </Helmet>

      <main>
        <div
          style={{
            backgroundSize: "cover",
            backgroundPositionY: "center",
            backgroundPositionX: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${withPrefix("/images/wind-muehle.jpg")})`,
          }}
          className="relative h-screen w-full flex flex-col justify-center items-center"
        >
          <div className="z-10 text-center">
            <h1 className="text-center text-white mb-3">
              {t("Die Zufriedenheit unserer")}
              <br /> {t("Kunden ist unser Antrieb.")}
            </h1>
          </div>
          <div className="absolute w-full h-full bg-black opacity-40" />
        </div>

        <div className="flex flex-col justify-center items-center md:h-screen">
          <div className="mb-3 md:mb-10">
            <Header2 content={t("Unsere Geschichte")} />
          </div>
          <div
            style={{
              maxWidth: 1200,
            }}
            className="flex flex-col justify-start md:flex-row text-center md:text-left mx-auto px-3 mb-3 md:mb-0"
          >
            <div
              style={{
                borderColor: "#f7a600",
              }}
              className="py-3 md:py-0 px-3 border-dashed border-b-2 md:px-20 md:border-r-2 md:border-b-0 md:w-1/2 md:leading-7 md:text-xl"
            >
              {t("p-1")}
            </div>
            <div className="py-3 md:py-0 px-3 md:px-20 md:w-1/2 md:leading-7 md:text-xl">
              <p className="mb-6">{t("p-2")}</p>
              <p className="mb-6">{t("p-3")}</p>
              <p className="mb-6">{t("p-4")}</p>
              <p className="mb-6">{t("p-5")}</p>
            </div>
          </div>
        </div>

        <div className="md:h-screen flex flex-col md:items-center md:justify-center mb-3 md:mb-0">
          <div className="mb-3">
            <Header2 content={t("Unser Partner")} />
          </div>

          <iframe
            style={{
              height:
                deviceType === "mobile" || deviceType === "tablet" ? 300 : 600,
            }}
            className="w-full md:w-3/4 mb-10 md:mb-44 mx-auto px-2"
            src="https://www.youtube.com/embed/0pau93zntUU"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </main>
    </Layout>
  );
};

export default AboutUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["header", "about-us", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
